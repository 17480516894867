import React from 'react'
import InputField from '../../components/miscBlocks/inputField'
import { getRefundDetails } from '../../utils/Enums'

const UserPaymentDetails = ({ bookingTransactionDetails }) => {
    return (
        <div className='mt-12'>
            <div className="card">
                <div className="event-title-container flex justify-center items-center">
                    <h1 className="event-name mb-0">Payment Details</h1>
                </div>
                <div className='mt-8 flex flex-col gap-5'>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Transaction Id: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails.transactionId : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Booking Date: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails?.bookingDate : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Card Number: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? '********' + bookingTransactionDetails?.paymentMethodLast4 : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Payment Method Type: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails?.paymentMethodType : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Payment Method Type Country: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails?.paymentMethodTypeCountry : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Status: </div>
                        <div className='text-lg'>{bookingTransactionDetails ?
                            getRefundDetails(bookingTransactionDetails?.status)
                            : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Number Of Tickets: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails?.numberOfTickets : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Currency: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? bookingTransactionDetails?.currency : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Ticket Price: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? '$' + bookingTransactionDetails?.ticketPrice : "Loading..."}</div>
                    </div>
                    {bookingTransactionDetails && bookingTransactionDetails?.discountPercentage && 
                        <>
                            <div className='sm:flex justify-between'>
                                <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Discounted Percentage: </div>
                                <div className='text-lg'>{bookingTransactionDetails ?  bookingTransactionDetails?.discountPercentage + '%' : "Loading..."}</div>
                            </div>
                            <div className='sm:flex justify-between'>
                                <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Discounted Ticket Price: </div>
                                <div className='text-lg'>{bookingTransactionDetails ? '$' + bookingTransactionDetails?.discountedTicketPrice : "Loading..."}</div>
                            </div>
                        </>
                    }
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Processing Fees: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? '$' + bookingTransactionDetails?.stripeProcessingFees.toFixed(2) : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600 dark:text-gray-400'>Total Booking Amount: </div>
                        <div className='text-lg'>{bookingTransactionDetails ? '$' + bookingTransactionDetails?.totalBookingAmount : "Loading..."}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPaymentDetails
