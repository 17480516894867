
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicDocumentSearch from '../../../components/analytics/dynamicDocumentSearch';
import DocumentPreviewPopup from '../../../components/analytics/documentPreviewPopup';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
import AnalyticGraphParticipantToEvent from '../../../components/analytics/analyticGraphParticipantToEvent';
import { calculateWidth, processEventsData, performDocumentSearch, fetchTopWords, fetchForms, fetchTranscripts } from './user-dashboard-event-analytics-utility';
import UserStatusModel from '../../../statuscodemanage/user-status-model';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';
import { removeLocalStorage } from '../../../utils/removeLocalstorage';
import PageLoader from '../../../utils/PageLoader';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

const UserDashboardEventAnalytics = () => {
  const [createdEvents, setCreatedEvents] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timePeriod, setTimePeriod] = useState('all');
  const [timePeriodTranscript, setTimePeriodTranscript] = useState('all');
  const [graphData, setGraphData] = useState([]);
  const [chartWidth, setChartWidth] = useState(calculateWidth(80));
  const [hashtagTimePeriod, setHashtagTimePeriod] = useState('all');
  const [topWords, setTopWords] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedSingleId, setSelectedSingleId] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [transcriptData, setTranscriptData] = useState([])
  const [response, setResponse] = useState({})
  const [pageLoading, setPageLoading] = useState(false)

  // Other states and hooks for the document preview popup
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const [currentDocumentContent, setCurrentDocumentContent] = useState('');
  const [currentDocumentTitle, setCurrentDocumentTitle] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);

  const navigate = useNavigate();

  const eventId = localStorage.getItem('userId')


  useEffect(() => {
    const handleResize = () => {
      setChartWidth(calculateWidth(60)); // Update width on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);


  useEffect(() => {
    const fetchUserDataAndEvents = async () => {
      setIsLoading(true);
      setPageLoading(true)
      try {
        const eventsResponse = await fetch(`${PORT_BACKEND_URL_SRC}/api/creator-event-list`, {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('authKey')
          },
          //credentials: 'include',
        });
        const eventsData = await eventsResponse.json();
        handleSpotifyStatusCode(eventsResponse.status, eventsData.message)
        setResponse({ response: eventsResponse, data: eventsData })
        if (eventsResponse.status === 211) {
          alert(eventsData?.message)
          removeLocalStorage()
          navigate('/')
        }
        if (eventsResponse.status === 200) {
          if (eventsData.success) {
            setCreatedEvents(eventsData.events);
            setFilteredEvents(eventsData.events)
            setSelectedSingleId([eventsData.events[0]._id])
          } else {
            alert('You do not have permission to view this page or no events found.');
          }
        }
      } catch (error) {
        console.error('Error fetching created events:', error);
      } finally {
        setIsLoading(false);
        setPageLoading(false)
      }


    };

    fetchUserDataAndEvents();
  }, [navigate]);

  useEffect(() => {
    if (createdEvents.length > 0) {
      const processedData = processEventsData(createdEvents, timePeriod);
      setGraphData(processedData);
    }
  }, [createdEvents, timePeriod, processEventsData]);

  const getTimePeriodChange = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/get-top-words-for-events?eventId=${eventId}&timeFilter=${hashtagTimePeriod}`, {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('authKey')
        }
      });
      const data = await response.json();
      setLoading(false)
      setTopWords(data.topWords);
    } catch (error) {
      console.error('Error fetching top words:', error);
    }
  }

  const filterEventsByTimePeriod = useCallback((timePeriod) => {
    const now = new Date();
    switch (timePeriod) {
      case 'month':
        return createdEvents.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 1)));
      case '3months':
        return createdEvents.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 3)));
      case '6months':
        return createdEvents.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 6)));
      case 'year':
        return createdEvents.filter(event => new Date(event.eventTime) > new Date(now.setFullYear(now.getFullYear() - 1)));
      default:
        return createdEvents;
    }
  }, [createdEvents]);

  useEffect(() => {
    setFilteredEvents(filterEventsByTimePeriod(timePeriod));
  }, [timePeriodTranscript, filterEventsByTimePeriod]);

  useEffect(() => {
    getTimePeriodChange()
  }, [hashtagTimePeriod])

  // Updated handleHashtagTimePeriodChange function
  const handleHashtagTimePeriodChange = async (e) => {
    setLoading(true)
    const newTimePeriod = e.target.value;
    setHashtagTimePeriod(newTimePeriod);
    const eventId = localStorage.getItem('userId')
  };

  const handleTimePeriodChangeTrascript = (e) => {
    setTimePeriodTranscript(e.target.value);
  };


  const [searchTerm, setSearchTerm] = useState('');
  const [eventSearchTerm, setEventSearchTerm] = useState('');

  // Handle search term change
  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const handleSelectForm = async (eventId) => {
    // Show popup immediately with searching message
    setCurrentDocumentTitle("Form");
    setCurrentDocumentContent("Searching document...");
    setIsDocumentPopupOpen(true);

    // Fetch forms then update content
    const forms = await fetchForms(searchTerm, PORT_BACKEND_URL_SRC, currentUser, eventId);

    if (forms.length > 0) {
      const documentPreview = forms[0].documentPreview;
      let content = "";
      for (let i = 0; i < documentPreview.questions.length; i++) {
        content += `Question ${i + 1}: ${documentPreview.questions[i]}\n`;
        content += `Answer ${i + 1}: ${documentPreview.answers[i]}\n\n`; // Add an extra newline for separation
      }
      setCurrentDocumentContent(content || "No form content available");
    } else {
      setCurrentDocumentContent("No form content available");
    }
  };


  // Function to handle transcript document selection
  const handleSelectTranscript = async (transcriptId) => {
    // Show popup immediately with searching message
    setCurrentDocumentContent("Searching document...");
    setIsDocumentPopupOpen(true);
    setTranscriptData([])

    // Fetch transcripts then update content
    const transcripts = await fetchTranscripts(PORT_BACKEND_URL_SRC, transcriptId);
    if (transcripts?.data?.messages?.length > 0) {
      setCurrentDocumentTitle(transcripts?.data?.participantInfo?.firstName + " " + transcripts?.data?.participantInfo?.lastName + "'s transcript");
      setTranscriptData(transcripts)
    } else {
      setCurrentDocumentTitle("Transcript");
      setCurrentDocumentContent("No transcript content available");
    }
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleEventSelect = (eventId) => {
    const isAlreadySelected = selectedEvents.includes(eventId);
    if (eventId) {
      setSelectedEvents(isAlreadySelected
        ? selectedEvents.filter(id => id !== eventId)
        : [...selectedEvents, eventId]);
    }
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setEventSearchTerm(newSearchTerm);
    // Filter the events based on the new search term
    const newFilteredEvents = createdEvents.filter(event => {
      // Check if 'eventName' is defined and is a string
      return event.eventName && typeof event.eventName === 'string' &&
        event.eventName.toLowerCase().includes(newSearchTerm.toLowerCase());
    });

    setFilteredEvents(newFilteredEvents);
  };



  // Perform search when searchTerm changes
  useEffect(() => {
    if (selectedSingleId.length > 0) {
      performDocumentSearch(searchTerm, PORT_BACKEND_URL_SRC, setSearchResults, selectedEvents, selectedSingleId, currentPage);
    }
  }, [searchTerm, selectedEvents, currentPage, selectedSingleId, PORT_BACKEND_URL_SRC, currentUser]);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < searchResults?.data?.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm])

  return (
    <>{pageLoading ? <PageLoader/> : 
    <div className="basic-container">
      <UserStatusModel eventResponse={response} />
      <div className="button-container">
        <div className="button-wrapper"><DetailBackButton /></div>
        {/* <div className="button-wrapper"><DetailDashboardButton /></div> */}
      </div>

      <div className="card">
        <DetailEventTitle eventName={"Engagement"} />
      </div>

      <div className="card">
        <DetailEventDescription description={"See in the graph below how many participants joined your events in time."} />
      </div>

      <div className="card">
        <select value={timePeriod} className='dark:bg-black' onChange={handleTimePeriodChange}>
          <option value="month">Last Month</option>
          <option value="3months">Last 3 Months</option>
          <option value="6months">Last 6 Months</option>
          <option value="year">Last Year</option>
          <option value="all">All Time</option>
        </select>
      </div>

      {!isLoading && createdEvents.length > 0 && (
        <div>
          <div className="card" >
            <AnalyticGraphParticipantToEvent graphData={graphData} chartWidth={chartWidth} />
          </div>
          <div className="card">
            <DetailEventTitle eventName={"Hashtags"} />
          </div>
          <div className="card">
            <DetailEventDescription description={"These are the top 10 words used in your audience during the pre-booking experience"} />
          </div>
          <div className="card">
            <select value={hashtagTimePeriod} className='dark:bg-black' onChange={handleHashtagTimePeriodChange}>
              <option value="month">Last Month</option>
              <option value="3months">Last 3 Months</option>
              <option value="6months">Last 6 Months</option>
              <option value="year">Last Year</option>
              <option value="all">All Time</option>
            </select>
          </div>
          <div className="card">
            {!loading ? <ul>
              {topWords?.map((word, index) => (
                <li key={index}>{word}</li>
              ))}
            </ul> : "Loading..."}
          </div>
        </div>
      )}

      <div className="card">
        <DetailEventTitle eventName={"Free search"} />
      </div>
      <div className="card">
        <DetailEventDescription description={"Search previous events and see what the participants said"} />
      </div>

      <div className="card">
        {/* Time Period Dropdown */}
        <div className="input-group">
          <label htmlFor="timePeriod"></label>
          <select id="timePeriod" className='dark:bg-black' value={timePeriodTranscript} onChange={handleTimePeriodChangeTrascript}>
            <option value="all">All Time</option>
            <option value="year">Last Year</option>
            <option value="6months">Last 6 Months</option>
            <option value="3months">Last 3 Months</option>
            <option value="month">Last Month</option>
          </select>
        </div>
      </div>

      <div className="card mt-5 mb-5">
        {/* <div className='flex flex-col mt-4'> */}
        <label className='text-lg font-medium'></label>
        <input
          value={eventSearchTerm}
          onChange={handleSearch}
          className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
          placeholder='Type to search events'
          type='text'
        />
        {/* </div> */}
      </div>

      <div className="card" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {filteredEvents.map((event) => (
          <div key={event._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <input
              type="checkbox"
              id={`event-${event._id}`}
              checked={selectedEvents.length > 0 ? selectedEvents.includes(event._id) : event._id === selectedSingleId[0]}
              onChange={() => handleEventSelect(event._id)}
              style={{ marginRight: '10px' }}
            />
            <label htmlFor={`event-${event._id}`}>{event.eventName}</label>
          </div>
        ))}
      </div>

      <DynamicDocumentSearch performSearch={handleSearchChange} />

      {/* Render search results */}
      <div style={{ maxHeight: '500px', overflowY: 'auto', marginTop: '20px' }}>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>Event</th>
              <th>Participant Name</th>
              <th>Document Preview</th>
            </tr>
          </thead>

          <tbody>
            {searchResults?.data?.chatTranscriptList?.map((result, index) => (
              <tr key={index}>
                <td>{result.eventInfo.eventName}</td>
                <td><div className='flex flex-col sm:flex-row sm:justify-center items-center gap-2'><img className='w-16 h-16 rounded-full' src={PORT_BACKEND_URL_SRC + '/' + result.partcipantInfo.profilePic} alt='Profile Pic' /> {result.partcipantInfo?.firstName + ' ' + result.partcipantInfo?.lastName}</div></td>
                <td className="flex justify-center gap-2">
                  {/*<button onClick={() => handleSelectForm(result.eventId)} className="back-button">View Form</button>*/}
                  <button onClick={() => handleSelectTranscript(result._id)} className="back-button !bg-[#686D76]">View Transcript</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {searchResults?.data?.totalPages > 0 && <div className='flex justify-center gap-4 items-center mt-12'>
        <div className="flex gap-2">
          <button className='!bg-[#686D76] px-3 py-2 text-white prev-next-button' onClick={handlePrev}>Prev</button>
          <button className='!bg-[#686D76] px-3 py-2 text-white prev-next-button' onClick={handleNext}>Next</button>
        </div>
        <div>
          <span className="bg-white px-3 py-2 text-black border-2">{currentPage}</span>
          <span className='ml-2'>of</span>
          <span className='ml-2'>{searchResults?.data?.totalPages} Pages</span>
        </div>
      </div>}
      {/* At the very end, just before the closing tag of the container */}
      {isDocumentPopupOpen && (
        <DocumentPreviewPopup
          documentContent={transcriptData}
          documentTitle={currentDocumentTitle}
          onClose={() => setIsDocumentPopupOpen(false)}
        />
      )}
    </div>
  }</>
  );
}

export default UserDashboardEventAnalytics;

