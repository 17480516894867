export const getRefundDetails = (status) => {
    switch (status) {
        case 'booked':
            return 'Booked';
            break;
        case 'pre-booked':
            return 'Pre Booked';
            break;
        case 'confirmed':
            return 'Confirmed';
            break;
        case 'checked-in':
            return 'Checked In';
            break;
        case 'cancelled':
            return 'Cancelled';
            break;
        case 'refund-failed':
            return 'Refund Failed';
            break;
        case 'refund-requested':
            return 'Refund Requested';
            break;
        case 'refund-requested':
            return 'Refund Requested';
            break;
        case 'refund-processed':
            return 'Refund Processed';
            break;
        case 'refund-completed':
            return 'Refund Completed';
            break;
        case 'payment-failed':
            return 'Payment Failed';
            break;
        default:
            break
    }
}