import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ConnectToSpotify from '../../../components/authBlocks/connectToSpotify';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function UserSpotifyLogin() {
    const navigate = useNavigate();  // Hook for navigation
    const userId = localStorage.getItem('userId');
    const eventId = localStorage.getItem('eventId'); // Fetching eventId from localStorage

    const handleSpotifyLogin = async () => {
        if (!userId) {
            alert('User ID is required for Spotify login.');
            return;
        }

        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/spotify-authorize-url?userId=${userId}&eventId=${eventId}`, {

                method: 'GET',
            });

            const data = await response.json();
            if (data.success && data.spotifyAuthorizeURL) {
                // Redirect the user to Spotify's authorization page
                window.location.href = data.spotifyAuthorizeURL;
            } else if (data.success && data.redirectUrl) {
                // If the login is successful and a redirect URL is provided, navigate there
                navigate(data.redirectUrl);
            } else {
                throw new Error('Failed to retrieve Spotify authorization URL');
            }
        } catch (error) {
            console.error('Error during Spotify login:', error);
            alert('Failed to initiate Spotify authentication. Please try again.');
        }
    };

    const handleSkip = () => {
        navigate('/user-buy-ticket')
    }

    return <ConnectToSpotify handleSpotifyLogin={handleSpotifyLogin} handleSkip={handleSkip}/>;
}

export default UserSpotifyLogin;

