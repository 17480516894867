import React, { useState, useEffect } from "react";
import InputField from "../miscBlocks/inputField";
import DetailEventTitle from "../events/detailSection/detailEventTitle";
import DetailBackButton from "../events/detailSection/detailBackButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DragAndDropImage from "../miscBlocks/dragAndDropImage";
import { checkReferralCode } from "../../api/referralCodeAPI";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useGoogleLogin } from "@react-oauth/google";
import { PORT_BACKEND_URL_SRC } from "../../srcLocalPortReferenceBackend";
import GoogleButton from 'react-google-button'
import GoogleLoginComponent, { googleLogin } from "../../utils/googleLogin";
import { customStyles } from "../../utils/getLocation";
import { SocialLinks } from 'social-links';

const socialLinks = new SocialLinks();

function SignUpForm({
  referralCode,
  setReferralCode,
  role,
  setRole,
  email,
  setEmail,
  password,
  setPassword,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dob,
  setDob,
  phoneNumber,
  setPhoneNumber,
  handleImageDrop,
  countryCode,
  setCountryCode, // Added props for country code
  name,
  setName,
  identifyHow,
  setIdentifyHow, // New props // Spotify props
  HandleSignup,
  isLoading,
  userRole, // Added handleVerifySpotify and spotifyVerificationStatus props
  setReferralCodeId,
  setCoordinates,
  instagram,
  setInstagram,
  twitter,
  setTwitter,
  socialMediaValid,
  setSocialMediaValid
}) {
  const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const isBookingFlow = location.state && location.state.isRegularUser;
  const [valid, setValid] = useState({
    valid: null,
    text: ''
  })
  const navigate = useNavigate()

  // Example country codes - you can expand this list
  const countryCodes = ["Select one option", "+1"];

  // Identify as options
  const identifyOptions = [
    "Select one option",
    "Cisgender Male",
    "Cisgender Female",
    "Transgender Male",
    "Transgender Female",
    "I prefer not to say",
    "Other",
  ];

  React.useEffect(() => {
    if (isBookingFlow) {
      setRole("regular");
    }
  }, [isBookingFlow, setRole]);

  

  const handleBlur = async (val) => {
    if (val !== '') {
      setValid({ ...valid, text: 'Loading...' })
      const newData = await checkReferralCode(val, 'SIGNUP_PAGE')
      setReferralCodeId(newData?.data?.signupReferralCodeId)
      setValid({ ...valid, text: newData?.message, valid: newData?.success })
    }
    else {
      setValid({ ...valid, text: '', valid: false })
    }
  };

  const fetchCoordinatesFromPlaceId = (placeId, label) => {
    return new Promise((resolve, reject) => {
      // Create a PlacesService instance
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      // Fetch place details
      service.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          // Get latitude and longitude
          const { location } = place.geometry;
          setCoordinates({
            longitude: location.lng(),
            latitude: location.lat(),
            placeId: placeId,
            label: label
          })
        } else {
          // Handle errors
          reject('Failed to fetch coordinates');
        }
      });
    });
  };

  const handlePlaceChange = (place) => {
    fetchCoordinatesFromPlaceId(place.value.place_id, place.label);
  };

  return (
    <div className="w-11/12 dark:bg-[#191a1b] max-w-[700px] p-4 sm:px-10 sm:py-20 rounded-3xl bg-white border-0 border-white">
      <div className="button-container">
        <div className="button-wrapper">
          <DetailBackButton />
        </div>
      </div>

      <div className="card">
        <DetailEventTitle eventName={"Sign up"} />
      </div>

      <div className="mt-8">
        <div className="mt-8 mb-4">
          {!isBookingFlow && (
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              You are signing up as a creator.
            </p>
          )}

          {isBookingFlow && (
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              You are signing up as a regular user. You will be redirected to
              the bookings page after the signup.
            </p>
          )}
          <div className="my-6 flex justify-center google-login-component">
            <GoogleLoginComponent userRole={userRole} character={location?.state?.character} label={"Signup with Google"} />
          </div>
          <div>
            <div className='flex justify-center items-center my-6'>
              <div className='w-[45%] h-[1px] bg-gray-600 dark:bg-gray-300'></div>
              <div className='px-2 text-lg text-gray-600 dark:text-gray-300'>or</div>
              <div className='w-[45%] h-[1px] bg-gray-600 dark:bg-gray-300'></div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className='text-lg font-medium'>Profile Picture</div>
          <DragAndDropImage onImageDrop={handleImageDrop} />
        </div>
        {/* Existing Input Fields */}
        <InputField
          label="Email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="card mt-5 mb-5">
          <label className="text-lg font-medium">Password</label>
          <div className="relative mt-1">
            <input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 bg-transparent"
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-4 text-gray-600 focus:outline-none"
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
        </div>

        <InputField
          label="First Name"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputField
          label="Last Name"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Phone Number</label>
          <PhoneInput
            country={'us'}
            inputStyle={{ width: '100%', height: "50px", borderColor: "rgb(243 244 246)", borderWidth: '2px' }}
            value={phoneNumber}
            className="phone-input"
            onChange={(phone) => setPhoneNumber(phone)}
          />
        </div>
        <div className="card mt-5 mb-5">
          {/* <div className='flex flex-col mt-4'> */}
          <label className='text-lg font-medium'>Date of Birth</label>
          <input
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
            placeholder='Date of Birth'
            max="9999-12-31"
            type="date"
          />
        </div>
        {role === "regular" && <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Location</label>
          <GooglePlacesAutocomplete
            apiKey={googleMapAPIKey}
            selectProps={{
              styles: customStyles,
              placeholder: 'Search for a place...',
              onChange: handlePlaceChange,
            }}
            onLoadFailed={(error) => (
              console.error("Could not inject Google script", error)
            )}
          />
          <div className="font-bold text-xs mt-2">Note: This location will help to discover events happening nearby</div>
        </div>
        }
        {role === "regular" &&
          <>
            <div className="card mt-5 mb-5">
              <label className='text-lg font-medium'>
                Instagram User Name
              </label>
              <input
                name='instagram'
                className='focus:outline-none focus:border-black focus:ring-0 w-full dark:border-gray-800 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                placeholder="Instagram User Name"
                type="text"
                onBlur={(e) => {
                  if (instagram) {
                    if(instagram.includes('instagram.com') || instagram.includes('/')){
                      setSocialMediaValid({ ...socialMediaValid, instagram: false })
                    }
                    else{
                      setSocialMediaValid({ ...socialMediaValid, instagram: true })
                    }
                  }
                }}
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                required
              />
              {(socialMediaValid?.instagram !== true && socialMediaValid?.instagram !== '') && <div className={`text-sm ml-4 mt-3 text-red-600`}>❌ Invalid user name</div>}
            </div>
            <div className="card mt-5 mb-5">
              <label className='text-lg font-medium'>
                Twitter User Name
              </label>
              <input
                name='twitterProfile'
                className='focus:outline-none focus:border-black focus:ring-0 w-full dark:border-gray-800 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                placeholder="Twitter User Name"
                type="text"
                onBlur={(e) => {
                  if(twitter){
                    if(twitter.includes('x.com') || twitter.includes('/')){
                      setSocialMediaValid({ ...socialMediaValid, twitter: false })
                    }
                    else{
                      setSocialMediaValid({ ...socialMediaValid, twitter: true })
                    }
                  }
                }}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                required
              />
              {(socialMediaValid?.twitter !== true && socialMediaValid?.twitter !== '') && <div className={`text-sm ml-4 mt-3 text-red-600`}>❌ Invalid user name</div>}
            </div>
          </>
        }
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>
            Referral Code
          </label>
          <input
            name='referralcode'
            className='focus:outline-none focus:border-black focus:ring-0 w-full dark:border-gray-800 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
            placeholder="Enter referral code"
            type="text"
            minLength={8}
            maxLength={8}
            onBlur={(e) => handleBlur(e.target.value)}
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            required
          />
          {valid?.text && valid?.text != 'Loading...' && <div className={`text-sm ml-4 mt-3 ${valid?.valid ? 'text-green-600' : 'text-red-600'}`}> {valid?.valid ? "✅" : "❌"}  {valid?.text}</div>}
          {valid?.text == 'Loading...' && <div className='text-sm ml-4 mt-3'>Loading...</div>}
        </div>
        {!isBookingFlow && <InputField
          label=" Name"
          placeholder=" Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />}

        {/* Identify As Dropdown */}
        <div className="mt-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
            Identify As (reach out to us if you don't see your identity)
          </label>
          <select
            value={identifyHow}
            onChange={(e) => setIdentifyHow(e.target.value)}
            className="focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
          >
            {identifyOptions.map((option) => (
              <option className="dark:bg-black" key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-2 mt-4 items-start">
          <input  type="checkbox" className="w-6 mt-2 cursor-pointer"/>
          <div className="text-md">I have read and agree to the <Link className="text-[#4535C1]" to='/terms-of-service'>Terms of Service</Link> and <Link to='/privacy-policy' className="text-[#4535C1]">Privacy Policy</Link></div>
        </div>

        <div className="mt-4">
          <p className="text-sm text-gray-400">
            This experience will require Spotify credentials to create your
            personal music profile and also premium account is highly recommended
          </p>
        </div>

        <div className="mt-8 flex flex-col gap-y-4">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <button
              onClick={() => {
                if (userRole === 'regular') {
                  if (socialMediaValid?.instagram === true && socialMediaValid?.twitter === true) {
                    HandleSignup()
                  }
                }
                else {
                  HandleSignup()
                }
              }}
              className="animated-btn"
            >
              <span className="text">Sign up</span>
            </button>
          )}
        </div>

        <div className="mt-8 flex justify-center items-center">
          <p className="font-medium text-base">Already have an account?</p>
          <Link to="/" className="ml-2 font-medium text-base text-[#4535C1]">
            Login
          </Link>
        </div>
        {/*<div className="mt-12 flex justify-center">
           <button onClick={() => googleSignup()}>Login with Google</button>
           <GoogleButton
            label='Signup with google'
            onClick={() => googleLogin(userRole)}
          /> 
          <GoogleLoginComponent userRole={userRole} label={"Signup with Google"} />
        </div>*/}
      </div>
    </div>
  );
}

export default SignUpForm;
