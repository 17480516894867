import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LoginForm = ({ email, setEmail, password, setPassword, rememberMe, loading, setRememberMe, handleLogin }) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div>
            {/* Email Input */}
            <div className='flex flex-col'>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                    placeholder="Enter your email"
                />
            </div>
            {/* Password Input */}
            <div className='flex flex-col mt-4 relative'>
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                />
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className='absolute right-4 top-5 text-gray-600'
                >
                    <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                </button>
            </div>
            {/* Remember Me and Forgot Password */}
            <div className='mt-8 flex justify-between items-center'>
                <div>
                    <input
                        type="checkbox"
                        id='remember'
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        className="scale-150"
                    />
                    <label className='ml-2 font-medium text-base' htmlFor="remember">Remember me</label>
                </div>
                <Link to="/user-forget-password" className='font-medium text-base text-[#4535C1]'>
                    Forgot password
                </Link>
            </div>
            {/* Sign In Button */}
            <div className='mt-8 flex flex-col gap-y-4'>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <button
                        onClick={() => handleLogin(email, password, rememberMe)}
                        className='animated-btn'>
                        <span className='text'>Sign in</span></button>
                )}
            </div>
        </div>
    );
}

export default LoginForm;
