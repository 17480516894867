const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const stripeConnect = async ({ userId, email }) => {
    fetch(`${PORT_BACKEND_URL_SRC}/stripe/connect?platform=web_app&userId=${userId}&email=${email}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to Connect with Stripe');
            }
            return response.json();
        })
        .then(data => {
            window.location.href = data.accountLink.url; // Use window.location to force a page refresh
        })
}