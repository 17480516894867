import React, { useState } from 'react';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useNavigate } from 'react-router-dom';

function HamburgerMenu({ currentUser, handleLogout, handleAnalytics, handleFreeChat, handleSettings, handleDiscovery, handleNewsletter, setMenuVisible, menuVisible, isCreator }) {
    const navigate = useNavigate()
    const menuIconStyle = {
        fontSize: '40px',
        cursor: 'pointer',
        color: 'black',
    };

    const [hoverIndex, setHoverIndex] = useState(null);

    const menuStyle = {
        width: '240px',
        padding: '1rem',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0 0 20px 0',
        transition: 'box-shadow 0.3s ease-in-out',
    };

    const menuItemStyle = (isHovered, index) => ({
        color: isHovered ? 'white' : 'black',
        fontWeight: 'bold',
        padding: '12px 20px',
        display: 'block',
        margin: '0px 0',
        borderRadius: '8px',
        backgroundColor: isHovered ? '#d3d3d3' : 'transparent',
        cursor: 'pointer',
        transition: 'all 0.05s ease',
        textAlign: 'center',
        border: isHovered ? '2px solid black' : '0px solid transparent',
        boxShadow: hoverIndex === index ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
    });

    return (
        <div className="absolute top-6 right-10 sm:top-10">
            <div className="relative text-violet-500">
                <img className='rounded-full cursor-pointer' height={80} width={80} onClick={() => setMenuVisible(!menuVisible)}  src={currentUser?.userProfilePic ? PORT_BACKEND_URL_SRC + '/' + currentUser?.userProfilePic : '/images/default_user_image.jpg'} alt="Profile Pic"/>
                {menuVisible && (
                    <div className="absolute top-full right-0 mt-2 rounded-md shadow-lg" style={menuStyle}>
                        {isCreator && <>
                            <div
                                onClick={handleAnalytics}
                                onMouseEnter={() => setHoverIndex(1)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 1, 1)}
                            >
                                Engagement
                            </div>

                            <div
                                onClick={handleNewsletter}
                                onMouseEnter={() => setHoverIndex(2)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 2, 2)}
                            >
                                Newsletter
                            </div>

                            <div
                                onClick={handleFreeChat}
                                onMouseEnter={() => setHoverIndex(3)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 3, 3)}
                            >
                                Free Chat
                            </div>
                        </>}
                        {
                            !isCreator && 
                            <div
                                onClick={handleDiscovery}
                                onMouseEnter={() => setHoverIndex(5)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 5, 5)}
                            >
                                Discovery
                            </div>
                        }
                        <div
                            onClick={() => navigate('/change-character')}
                            onMouseEnter={() => setHoverIndex(6)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 6, 6)}
                        >
                            Change Character
                        </div>
                        <div
                            onClick={handleSettings}
                            onMouseEnter={() => setHoverIndex(4)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 4, 4)}
                        >
                            Settings
                        </div>
                        <div
                            onClick={handleLogout}
                            onMouseEnter={() => setHoverIndex(0)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 0, 0)}
                        >
                            Logout
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HamburgerMenu;
