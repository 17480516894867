import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';

const EventCard = ({ event }) => {
    const navigate = useNavigate(); // Call useNavigate inside the function body

    return (
        <div className="text-white gap-4 card flex mt-3 !bg-black cursor-pointer w-full sm:w-[350px] justify-between" onClick={() => window.open(`/event-details?eventId=${event?._id}`, '_blank')}>
            <img src={`${PORT_BACKEND_URL_SRC}/${event?.eventImage}`} alt="Event" className="rounded w-20 h-20" />
            <div className="text-md flex flex-col justify-between">
                <div>{event?.eventName}</div>
                <div className="text-sm text-gray-300 flex gap-1">
                    <img src="/images/location.png" className="w-4 h-4" alt="icon" />
                    {event?.address}
                </div>
            </div>
            <div>${event?.ticketPrice}</div>
        </div>
    );
};

export default EventCard;
