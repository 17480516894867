import '../../../styles/sharedStyle.css';
import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import DetailEventImage from '../../../components/events/detailSection/detailEventImage';
import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
import DetailEventDescriptionIndex from '../../../components/events/detailSection/detailEventDescriptionIndex';
import DetailEventTime from '../../../components/events/detailSection/detailEventTime';
import DetailEventLocation from '../../../components/events/detailSection/detailEventLocation';
import DetailSocialMediaLinks from '../../../components/events/detailSection/detailSocialMediaLinks';
import { useFetchEventDetailsWithoutAuth } from './user-dashboard-event-share-utility';
import { removeLocalStorage } from '../../../utils/removeLocalstorage';
import PageLoader from '../../../utils/PageLoader';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserDashboardEventShare() {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true)
  const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(eventId, PORT_BACKEND_URL_SRC, "SHARED_EVENT_LINK", setLoading);
  const navigate = useNavigate(); // Step 2: Initialize useNavigate to use it in your component
  const authKey = localStorage.getItem('authKey')
  localStorage.setItem('eventId', eventId)

  const checkUserAuthentication = async (eventId) => {
    if (authKey) {
      try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/check-regular-user-auth/${eventId}`, {
          headers: {
            'Authorization': authKey
          }
        });
        const apiData = await response.json()
        if (response.status === 211) {
          alert(apiData.message)
          removeLocalStorage()
          navigate('/')
        }
        else if (response.status === 244) {
          const userConfirmed = window.confirm(apiData.message);

          // Proceed only if user confirms
          if (userConfirmed) {
            navigate('/user-spotify-login')
          }
          else {
            navigate('/user-buy-ticket')
          }
        }
        else if (response.status === 200) {
          localStorage.setItem('authKey', apiData.data.token)
          navigate(`/user-chat-experience/${eventId}`)
        }
        else {
          alert(apiData.message)
          navigate('/')
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    }
    else {
      navigate('/');
    }
  };


  return (
    <>{loading ? <PageLoader /> :
      <div className="basic-container">
        {eventData ? (
          <>
            <div className="card">
              <DetailEventTitle eventName={eventData.eventName} />
            </div>
            <div className="flex-container">
              <div className="card flex-item">
                <DetailEventImage eventImage={eventData.eventImage} altText={eventData.eventName} />
              </div>
              <div className="card flex-item">
                <div className="card dark:bg-[#191a1b]">
                  <DetailEventDescriptionIndex name="Max Bookings" value={eventData.eventMaxBookings} />
                </div>
                <div className="card dark:bg-[#191a1b]">
                  <DetailEventDescriptionIndex name="Price" value={'$' + eventData.ticketPrice} />
                </div>
                {/*<div className="card">
                <DetailEventDescriptionIndex name="Pre Bookings" value={eventData.eventPreBookings} />
              </div>*/}
                {eventData.isFeatured === "Approved" && <div className="card">
                  <DetailEventDescriptionIndex name="Featured" value={eventData.isFeatured} />
                </div>}
              </div>
            </div>
            <div className="card">
              <DetailEventDescription description={eventData.eventDescription} />
            </div>
            <div className="card">
              <DetailEventTime eventTime={eventData.eventTime} />
            </div>
            <div className="card">
              <DetailEventLocation location={eventData.address} />
            </div>
            <h1 className="participants-title">Previews</h1>
            <p className="participants-description">
              Get a taste of the event.
            </p>
            <DetailSocialMediaLinks links={eventData.eventLinks} />

            <h1 className="participants-title"></h1>
            <p className="participants-description">
            </p>
            {/* <Link to='/guest-email' className='text-blue-600 underline float-right'>Book as a guest</Link> */}
            <div className="flex flex-col gap-3 justify-between md:flex-row mt-10 mb-4">
              <button
                type="button"
                className='py-6 px-24 !w-fit animated-btn rounded font-bold'
                onClick={() => checkUserAuthentication(eventId)} // Pass eventId as an argument
              >
                <span className='text'>Book</span>

              </button>
              <button
                type="button"
                className='py-6 px-20 !w-fit animated-btn rounded font-bold'
                onClick={() => navigate('/guest-email')} // Pass eventId as an argument
              >
                <span className='text'>Book as a guest</span>

              </button>
            </div>
          </>
        ) : (
          <p>Loading event details...</p>
        )}
      </div>} </>
  );
}

export default UserDashboardEventShare;

