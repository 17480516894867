import '../../../styles/sharedStyle.css';
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PasswordResetForm from '../../../components/authBlocks/passwordResetForm';
import ThreeDHeart from '../../../components/geometries/rotatingHeart';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserResetPassword() {
    const { token } = useParams();
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const navigate = useNavigate();

    const handleResetPassword = () => {
        if (!password || !confirmPassword) {
            alert('Please enter your new password and confirm it.');
            return;
        }

        if (password !== confirmPassword) {
            alert('Passwords do not match. Please try again.');
            return;
        }

        fetch(`${PORT_BACKEND_URL_SRC}/forget-password/${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password: password })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('Your password has been successfully reset.');
                    // redirect to login 
                    navigate('/');
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error during password reset:', error.message);
                alert('Error during password reset. Please try again.');
            });
    };
    return (
        <div className="full-screen">
        <div className="flex flex-wrap">
            <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
                    <PasswordResetForm
                        password={password}
                        setPassword={setPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        handleResetPassword={handleResetPassword}
                    />
                 </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserResetPassword;




