import { handleShareClick, handleEditClick, handleViewDetailsClick, handleLogout, handleNewsletter, handleAnalytics, handleCreateClick, handleSettings, renderEvents, closeSharePopup, handleFreeChat } from './user-dashboard-create-utility';
import '../../../styles/sharedStyle.css';
import './user-dashboard-create.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SharePreviewPopup from '../../../components/events/sharePreviewPopUp';
import HamburgerMenu from '../../../components/miscBlocks/hamburgerMenu';
import { toast, ToastContainer } from 'react-toastify'
import { getCurrentUser } from '../../../api/authAPI';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';
import UserStatusModel, { checkStatus } from '../../../statuscodemanage/user-status-model';
import SpotifyStripeConnectPopup from '../../../components/popup/SpotifyStripeConnectPopup';
import { spotifyConnect } from '../../../api/spotifyConnectApi';
import { removeLocalStorage } from '../../../utils/removeLocalstorage';
import PageLoader from '../../../utils/PageLoader';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

const UserDashboardCreate = () => {
  const [createdEvents, setCreatedEvents] = useState([]);
  const [isConnected, setIsConnected] = useState({
    stripe: null,
    spotify: true
  })
  const [email, setEmail] = useState('')
  const [popup, setPopup] = useState(false)
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedEventForShare, setSelectedEventForShare] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const navigate = useNavigate();

  const handleShareModified = (eventId) => {
    handleShareClick(createdEvents, eventId, setSelectedEventForShare, setShowSharePopup);
  };
  const handleLogoutModified = () => handleLogout(navigate, PORT_BACKEND_URL_SRC);
  const handleNewsletterModified = () => handleNewsletter(navigate);
  const handleAnalyticsModified = () => handleAnalytics(navigate);
  const handleCreateClickModified = () => {
    setPopup(true)
    if (isConnected?.stripe) {
      handleCreateClick(navigate)
    }
  };
  const handleSettingsModified = () => handleSettings(navigate);
  const handleFreeChatModified = () => handleFreeChat(navigate)
  const [message, setMessage] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [response, setResponse] = useState({})
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      if (key === 'message') {
        setMessage(value);
      } else if (key === 'statusCode') {
        setStatusCode(value);
      } else if (key === 'authKey') {
        setAuthKey(value);
      }
    })
  }, []);

  useEffect(() => {
    if (message && statusCode && authKey) {
      if (statusCode === '200') {
        alert(message);
      } else {
        alert(message);
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [message, statusCode, authKey]);


  const fetchUserDataAndEvents = async (newAuthKey) => {
    setIsLoading(true);
    try {
      const eventsResponse = await fetch(`${PORT_BACKEND_URL_SRC}/api/creator-event-list`, {
        method: 'GET',
        headers: { Authorization: newAuthKey && newAuthKey }
      });
      const eventsData = await eventsResponse.json();
      setResponse({ response: eventsResponse, data: eventsData })
      if (eventsResponse.status === 211) {
        alert(eventsData.message)
        removeLocalStorage()
        navigate('/')

      }
      if (eventsResponse.status === 200) {
        if (eventsData.success) {
          setCreatedEvents(eventsData.events);
          setIsConnected({ spotify: eventsData?.user?.isSpotifyAuthCompleted, stripe: eventsData?.user?.isStripeConnect })
          setEmail(eventsData?.user?.email)
          let users = await getCurrentUser(navigate, newAuthKey, setResponse, 'dashboard')
          setCurrentUser(users.data)

        } else {
          toast.error('You do not have permission to view this page or no events found.');
        }
      }
    } catch (error) {
      console.error('Error fetching created events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authKey && authKey.length > 2) {
      localStorage.setItem("authKey", authKey)
      fetchUserDataAndEvents(authKey);
    }
    else if (localStorage.getItem('authKey')) {
      fetchUserDataAndEvents(localStorage.getItem('authKey'));
    }
  }, [authKey]);

  const handleClick = async () => {
    const userConfirmed = window.confirm(
      "Once you authorize your Spotify account with our platform, this connection will be permanent and cannot be changed later. Please ensure that you're using the correct Spotify credentials before proceeding. If you're unsure, take a moment to verify your login details to avoid issues with account access in the future."
    );
    if (userConfirmed) {
      await spotifyConnect(navigate)
    }
  }

  return (
    <>
      {
        popup && !isConnected?.stripe && <SpotifyStripeConnectPopup email={email} setPopup={setPopup} isConnected={isConnected} />
      }
      {!isConnected?.spotify && <div className="w-full z-50">
        <div className="bg-[#686D76] text-center text-white py-2 text-sm z-50">Your spotify account is incomplete. Please click the <span className='text-blue-500 hover:underline cursor-pointer' onClick={handleClick}>link</span> to enhance your experience</div>
      </div>}
      <UserStatusModel eventResponse={response} />
      <div className="container mx-auto p-4">
        {currentUser ? (
          <>
            <div className='bg-black w-full hamburger-menu-position'>
              {/* Adjust the top and right values as needed */}
              <HamburgerMenu
                currentUser={currentUser}
                handleLogout={handleLogoutModified}
                handleNewsletter={handleNewsletterModified}
                handleAnalytics={handleAnalyticsModified}
                handleFreeChat={handleFreeChatModified}
                handleSettings={handleSettingsModified}
                setMenuVisible={setMenuVisible}
                menuVisible={menuVisible}
                isCreator={true}
              />
            </div>
            <h1 className="text-5xl font-bold mt-14 mb-10">Events</h1> {/* Increased margin-bottom */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8"> {/* Increased gap and margin-bottom */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <button
                  onClick={handleCreateClickModified}
                  className="create-button bg-[#686D76] hover:bg-[#686D76]"
                  style={{ width: '150px', height: '100px' }}
                >
                  Create +
                </button>
              </div>
            </div>
            {/* {renderEvents(createdEvents, isLoading, navigate,
            (eventId) => handleEditClick(navigate, eventId),
            (eventId) => handleViewDetailsClick(navigate, eventId),
            handleShareModified // Updated to use the modified handleShare function
          )} */}
            {renderEvents(createdEvents, isLoading,
              (eventId) => handleEditClick(navigate, eventId),
              (eventId) => handleViewDetailsClick(navigate, eventId),
              (eventId) => handleShareModified(eventId)
            )}
            {/* Render the SharePreviewPopup if an event is selected for sharing */}
            {showSharePopup && selectedEventForShare && (
              <div className='share-preview-overlay'>
                <div className='share-preview-content '>
                  <SharePreviewPopup
                    event={selectedEventForShare}
                    onClose={() => closeSharePopup(setSelectedEventForShare, setShowSharePopup)}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-between items-center mb-8"> {/* Increased margin-bottom */}
            {/* <h1 className="text-2xl font-bold">hold tight...</h1> */}
            <PageLoader />
          </div>
        )
        }
      </div>
      <ToastContainer />

    </>
  );
};



export default UserDashboardCreate;